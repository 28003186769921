<template>
  <div class="MessageTemplates-view-page w-100">
    <basic-subheader>
      <template v-slot:actions>
        <b-button class="btn ml-3" type="button" @click="returnPage">
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Arrows/arrow-left.svg" />
          </span>
          Trở về trước
        </b-button>
        <b-button
          class="btn btn-inactive ml-3"
          type="button"
          v-if="messageTemplatesData.isActive && isAdmin"
          @click="handleInactive(messageTemplatesData)"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/power.svg" />
          </span>
          Inactive
        </b-button>
        <b-button
          class="btn btn-active ml-3"
          type="button"
          v-if="!messageTemplatesData.isActive && isAdmin"
          @click="handleInactive(messageTemplatesData)"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/power.svg" />
          </span>
          Active
        </b-button>
        <b-button
          class="btn btn-success ml-3"
          type="button"
          v-if="isAdmin"
          @click.stop="handleUpdate(messageTemplatesData)"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/edit-2.svg" />
          </span>
          Chỉnh sửa
        </b-button>
      </template>
    </basic-subheader>
    <b-overlay :show="loading">
      <b-container fluid class="MessageTemplates-view-page__body mb-6 mt-6">
        <b-row Class="mt-8">
          <b-col>
            <div class="text-muted">Id:</div>
            <div>
              {{ messageTemplatesData.id }}
            </div>
          </b-col>
          <b-col>
            <div class="text-muted">IsDeleted:</div>
            <div>
              {{ messageTemplatesData.isDeleted }}
            </div>
          </b-col>
          <b-col>
            <div class="text-muted">CreateDatetime:</div>
            <div>
              {{ messageTemplatesData.createDatetime }}
            </div>
          </b-col>
        </b-row>
        <b-row Class="mt-8">
          <b-col>
            <div class="text-muted">UpdateDatetime:</div>
            <div>
              {{ messageTemplatesData.updateDatetime }}
            </div>
          </b-col>
          <b-col>
            <div class="text-muted">CreatorId:</div>
            <div>
              {{ messageTemplatesData.creatorId }}
            </div>
          </b-col>
          <b-col>
            <div class="text-muted">UpdaterId:</div>
            <div>
              {{ messageTemplatesData.updaterId }}
            </div>
          </b-col>
        </b-row>
        <b-row Class="mt-8">
          <b-col>
            <div class="text-muted">Name:</div>
            <div>
              {{ messageTemplatesData.name }}
            </div>
          </b-col>
          <b-col>
            <div class="text-muted">MessageType:</div>
            <div>
              {{ messageTemplatesData.messageType }}
            </div>
          </b-col>
          <b-col>
            <div class="text-muted">MessageContain:</div>
            <div>
              {{ messageTemplatesData.messageContain }}
            </div>
          </b-col>
        </b-row>
        <b-row Class="mt-8">
          <b-col>
            <div class="text-muted">Description:</div>
            <div>
              {{ messageTemplatesData.description }}
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      isFirstLoad: false,
      MessageTemplatesData: {},
    };
  },
  computed: {
    ...mapGetters(['currentUser']),
    isAdmin() {
      let roles = this.currentUser?.roles;
      if (roles && roles.length) {
        return roles.includes('Admin');
      } else {
        return false;
      }
    },
  },
  methods: {
    returnPage() {
      this.$router.push({
        name: 'messageTemplates_list',
      });
    },
    handleUpdate(item) {
      this.$router.push({
        name: 'messageTemplates_detail',
        params: {
          form_type: 'edit',
          id: item.id,
        },
      });
    },
    handleInactive(item) {
      let text = item.isActive ? 'Inactive' : 'Active';
      let btn = item.isActive ? 'btn-inactive' : 'btn-active';
      this.$swal({
        title: '',
        text: `Bạn có chắc muốn ${text} app user ${item.id} không?`,
        icon: '/media/svg/icons/SweetAlert/alert-triangle-red.svg',
        buttons: {
          cancel: {
            text: 'Quay lại',
            value: false,
            visible: true,
            className: 'btn btn-secondary',
            closeModal: true,
          },
          confirm: {
            text: `${text}`,
            value: true,
            visible: true,
            className: `btn ${btn}`,
            closeModal: true,
          },
        },
      }).then(
        function (result) {
          if (result) {
            // inactive all
            this.inactiveItem(item)
              .then(() => {
                this.loadMessageTemplatesData();
                this.$swal(
                  'Thành công!',
                  'Chúc mừng, bạn đã cập nhật thông tin MessageTemplates thành công!',
                  'success',
                );
              })
              .catch(() => {
                this.$swal('Lỗi!', 'Đã có lỗi xảy ra.', 'error');
              });
          }
        }.bind(this),
      );
    },
    async inactiveItem(item) {
      this.loading = true;
      try {
        let payload = new FormData();
        payload.append('id', item.id);
        payload.append('active', !item.isActive);
        await this.$api.put(`MessageTemplates`, payload);
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi!',
          msg: error,
        });
      } finally {
        this.loading = false;
      }
    },
    async loadMessageTemplatesData() {
      if (!this.$route.params.id) return;
      try {
        this.loading = true;
        const id = this.$route.params.id;
        let { data } = await this.$api.get(`MessageTemplates/${id}`);
        this.messageTemplatesData = data;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi!',
          msg: error,
        });
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.loadMessageTemplatesData();
  },
};
</script>
